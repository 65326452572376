import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {
	public logs: String = '';
	public ip: String = '';
	public password: String = '';
	public connected: boolean = false;
	public degHeading: number = 0;

	constructor(
		public alertController: AlertController,
		private http: HttpClient,
		public toastController: ToastController
	) {}

	public getCompassDirec() {
		const degtorad = Math.PI / 180; // Degree-to-Radian conversion
		window.addEventListener('deviceorientation', (event: any) => {
			const alpha = event.alpha;
			const beta = event.beta;
			const gamma = event.gamma;

			const _x = beta ? beta * degtorad : 0; // beta value
			const _y = gamma ? gamma * degtorad : 0; // gamma value
			const _z = alpha ? alpha * degtorad : 0; // alpha value

			const cX = Math.cos(_x);
			const cY = Math.cos(_y);
			const cZ = Math.cos(_z);
			const sX = Math.sin(_x);
			const sY = Math.sin(_y);
			const sZ = Math.sin(_z);

			// Calculate Vx and Vy components
			const Vx = -cZ * sY - sZ * sX * cY;
			const Vy = -sZ * sY + cZ * sX * cY;

			// Calculate compass heading
			let compassHeading = Math.atan(Vx / Vy);

			// Convert compass heading to use whole unit circle
			if (Vy < 0) {
				compassHeading += Math.PI;
			} else if (Vx < 0) {
				compassHeading += 2 * Math.PI;
			}
			console.log('Heading :: ' + compassHeading * Number(180 / Math.PI));
			return Number(compassHeading * (180 / Math.PI)).toFixed(0) + ' degree'; // Compass Heading (in degrees)
		});
	}

	public doGet(url: string) {
		return this.http.get(url);
	}

	public doPost(url: string, data: any) {
		return this.http.post(url, data);
	}

	async presentAlert(mesg) {
		const alert = await this.alertController.create({
			header: 'Alert',
			subHeader: '',
			message: mesg ? mesg : 'This is an alert message.',
			buttons: ['OK']
		});

		await alert.present();
	}

	async presentToast(mesg) {
		const toast = await this.toastController.create({
			message: mesg ? mesg : 'Toast',
			duration: 2000
		});
		toast.present();
	}

	async presentToastWithOptions() {
		const toast = await this.toastController.create({
			message: 'Click to Close',
			showCloseButton: true,
			position: 'top',
			closeButtonText: 'Done'
		});
		toast.present();
	}
}
