import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class LoadingServiceService {
	isLoading = false;

	constructor(public loadingController: LoadingController) {}

	async present(msg: string) {
		this.isLoading = true;
		return await this.loadingController
			.create({
				duration: 5000,
				spinner: 'crescent',
				message: msg,
				translucent: true,
				cssClass: 'customLoading'
			})
			.then((a) => {
				a.present().then(() => {
					console.log('presented');
					if (!this.isLoading) {
						a.dismiss().then(() => console.log('abort presenting'));
					}
				});
			});
	}

	async presentShort(msg: string) {
		this.isLoading = true;
		return await this.loadingController
			.create({
				duration: 2000,
				spinner: 'crescent',
				message: msg,
				translucent: true,
				cssClass: 'customLoading'
			})
			.then((a) => {
				a.present().then(() => {
					console.log('presented');
					if (!this.isLoading) {
						a.dismiss().then(() => console.log('abort presenting'));
					}
				});
			});
	}

	async dismiss() {
		this.isLoading = false;
		return await this.loadingController.dismiss().then(() => console.log('dismissed'));
	}
}
